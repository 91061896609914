import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material"
import { useField } from "formik"
import { FC, useState } from "react"
import { BiTargetLock, BiTrash } from "react-icons/bi"
import { RiErrorWarningFill } from "react-icons/ri"
import { useQuery } from "urql"
import { Maybe } from "../graphql/generated/client-types-and-hooks"
import { graphql } from "../graphql/generated/gql"
import { calculateProductionRate } from "../helpers/numbers/calculateProductionRate"
import { colors } from "../helpers/styles/colors"
import { uniqBy } from "../helpers/util-functions"
import { useHandleError } from "../hooks/useHandleError"
import { AddOrEditUnitModal } from "./CustomReportingUnits/AddOrEditUnitModal"
import { CreatableAutocomplete } from "./Formik/CreatableAutocomplete"
import { TextField } from "./Formik/TextField"
import { useModalProps } from "./Modals/hooks/useModalProps"
import { SectionHeader } from "./PageSectionHeader"
import { DeleteGoalModal } from "./Partials/Tasks/DeleteGoalModal"
import { TableAddButton } from "./TableAddButton"
import DeprecatedModal from "./deprecated/StandardModal"

type MultiUnitInputProps = {
  taskId?: string
  estimatedHours?: Maybe<number> | ""
  isPrimary?: boolean
  name: string
  disabled?: boolean
  contractId?: string
}

export type UnitGoalEntry = {
  id?: string
  deliverableUnitId?: string
  targetQuantity?: number
  isPrimary: boolean
}

const GetDeliverableUnitsQuery = graphql(`
  query GetDeliverableUnitsQuery($contractId: String) {
    deliverableUnits(contractId: $contractId) {
      id
      description
      unitOfMeasure
    }
  }
`)

export const MultiUnitInput: FC<MultiUnitInputProps> = ({
  taskId,
  estimatedHours,
  isPrimary = true,
  name,
  disabled,
  contractId,
}) => {
  const [selectedUnitGoal, setSelectedUnitGoal] = useState<UnitGoalEntry>()
  const deleteUnitGoalModalProps = useModalProps("Delete Task Goal")
  const theme = useTheme()
  const [{ data, fetching: fetchingDeliverableUnits, error }, refetchDeliverableUnits] = useQuery({
    query: GetDeliverableUnitsQuery,
    variables: { contractId },
  })
  useHandleError(error, "There was an error loading units.")

  const newDeliverableUnitModalProps = useModalProps("Add New Unit")
  const [descriptionToCreate, setDescriptionToCreate] = useState("")

  const [{ value: unitGoalEntries }, , { setValue: setUnitGoalEntries }] = useField<UnitGoalEntry[]>(name)

  const removeEntry = (index: number) => {
    const newEntries = [...unitGoalEntries]
    newEntries.splice(index, 1)
    setUnitGoalEntries(newEntries)
  }
  type UnitType = { id: string; description: string; unitOfMeasure: string }

  const deliverableUnits = uniqBy<UnitType>(data?.deliverableUnits?.flat() || [], (unit: UnitType) => unit.description)
  const headerStyles = { border: 0, paddingLeft: "24px" }
  const rowStyles = {
    paddingY: 0,
    width: 300,
    borderBottom: 0,
    borderTop: 1,
    borderColor: colors.gray[200],
    paddingLeft: "24px",
    paddingRight: "10px",
  }

  const hasCommonUnitsOfMeasure =
    new Set(
      unitGoalEntries
        ?.map((unitGoalEntry) => {
          const deliverableUnit = deliverableUnits.find((u) => u.id === unitGoalEntry.deliverableUnitId)
          return deliverableUnit?.unitOfMeasure
        })
        .filter((exists) => exists)
    ).size < 2

  return (
    <section>
      <SectionHeader hideBorder title={isPrimary ? "Reporting Units" : "Additional Units"} />
      <Typography variant="body2" sx={{ color: colors.gray[500], marginTop: 1 }}>
        {isPrimary
          ? "These units will be used in progress bar and production rate calculations."
          : "These units won't factor into progress bar or production rate calculations, but they will be reportable and visible in your project and task summaries."}
      </Typography>
      <TableContainer sx={{ border: 1, borderRadius: 2, borderColor: colors.gray[200], marginTop: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyles}>Unit Type</TableCell>
              <TableCell sx={headerStyles}>{isPrimary ? "Target Quantity" : "Estimated Quantity"}</TableCell>
              <TableCell sx={headerStyles}>Unit of Measure</TableCell>
              <TableCell sx={headerStyles}>{isPrimary ? "Production Rate" : ""}</TableCell>
              <TableCell sx={headerStyles}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {unitGoalEntries?.map((unitGoalEntry, i) => {
              const deliverableUnit = deliverableUnits.find((u) => u.id === unitGoalEntry.deliverableUnitId)
              const displayProductionRate = isPrimary && !!unitGoalEntry?.targetQuantity
              return (
                <TableRow key={i}>
                  <TableCell sx={rowStyles}>
                    <CreatableAutocomplete
                      name={`${name}[${i}].deliverableUnitId`}
                      className="mt-[18px] min-w-full"
                      variant="outlined"
                      label=""
                      placeholder="Select Unit"
                      defaultValue={deliverableUnit?.id}
                      disableCreate={!!contractId}
                      disabled={fetchingDeliverableUnits || disabled}
                      options={deliverableUnits
                        .filter((du) => du.id)
                        .map((du) => ({
                          id: du.id,
                          label: du.description,
                        }))}
                      onCreate={(value) => {
                        newDeliverableUnitModalProps.handleOpenModal()
                        setDescriptionToCreate(value)
                      }}
                    />
                  </TableCell>
                  <TableCell sx={rowStyles}>
                    <TextField
                      sx={{ marginTop: "18px" }}
                      name={`${name}[${i}].targetQuantity`}
                      variant="outlined"
                      placeholder="Amount"
                      type="number"
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell sx={rowStyles}>{deliverableUnit?.unitOfMeasure}</TableCell>
                  <TableCell sx={rowStyles}>
                    {displayProductionRate &&
                      (hasCommonUnitsOfMeasure ? (
                        <Typography color="primary" className="flex items-center gap-2">
                          <BiTargetLock fontSize={20} />
                          {calculateProductionRate(unitGoalEntries, estimatedHours)} per man-hour
                        </Typography>
                      ) : (
                        <Typography color={theme.palette.warning.main} className="flex items-center gap-2">
                          <RiErrorWarningFill fontSize={20} />
                          Check unit types
                        </Typography>
                      ))}
                  </TableCell>
                  <TableCell sx={{ ...rowStyles, width: "inherit" }} align="right">
                    <IconButton
                      size="large"
                      onClick={() => {
                        if (unitGoalEntries[i]?.id) {
                          setSelectedUnitGoal(unitGoalEntries[i])
                          deleteUnitGoalModalProps.handleOpenModal()
                        } else {
                          removeEntry(i)
                        }
                      }}
                      sx={{ justifySelf: "end" }}
                      disabled={disabled}
                    >
                      <BiTrash size="20px" className="text-gray-400 text-[18px]" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TableAddButton
        label={isPrimary ? "Add reporting unit" : "Add additional unit"}
        fullWidth
        onClick={() =>
          setUnitGoalEntries([
            ...unitGoalEntries,
            { isPrimary, deliverableUnitId: undefined, targetQuantity: undefined },
          ])
        }
        disabled={disabled}
      />

      {newDeliverableUnitModalProps.isOpen && (
        <AddOrEditUnitModal
          {...newDeliverableUnitModalProps}
          initialValues={{ description: descriptionToCreate }}
          onSuccess={(successData) => {
            newDeliverableUnitModalProps.handleCloseModal()
            setUnitGoalEntries(
              unitGoalEntries?.map((entry) =>
                entry.deliverableUnitId === successData.description
                  ? { ...entry, deliverableUnitId: successData.id }
                  : entry
              )
            )
            refetchDeliverableUnits()
          }}
        />
      )}
      {taskId && deleteUnitGoalModalProps.isOpen && (
        <DeprecatedModal {...deleteUnitGoalModalProps}>
          <DeleteGoalModal
            closeModal={deleteUnitGoalModalProps.handleCloseModal}
            goal={selectedUnitGoal!}
            taskId={taskId}
          />
        </DeprecatedModal>
      )}
    </section>
  )
}
