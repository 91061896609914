import { EditGroupTaskQuery, EditTaskOrSubtaskQuery } from "../../../../graphql/generated/client-types-and-hooks"
import { MetadataNote } from "../../../../types/MetadataNote"
import { CreateOrEditTaskFormValues } from "./types"

export const getInitialValues = (
  addTaskType: CreateOrEditTaskFormValues["taskType"] | undefined,
  isCreationNotEdit: boolean,
  taskOrSubtask?: EditTaskOrSubtaskQuery["task"],
  groupTask?: EditGroupTaskQuery["taskGroup"],
  taskGroupId?: string
): CreateOrEditTaskFormValues => {
  const initialTaskType = getInitialTaskType(addTaskType, taskOrSubtask)

  const unitGoalInputs = (taskOrSubtask?.unitGoals || []).map(({ id, deliverableUnit, isPrimary, targetQuantity }) => ({
    id: id || undefined,
    deliverableUnitId: deliverableUnit.id,
    isPrimary,
    targetQuantity: targetQuantity || 0,
  }))

  const metadata = (taskOrSubtask?.metadata ?? groupTask?.notes ?? []) as MetadataNote[]

  const commonFields = {
    taskType: initialTaskType,
    metadata,
    metadataToRemove: [] as MetadataNote[],
    endDate: null,
    startDate: null,
    duration: null,
    workDays: null,
  }

  if (isCreationNotEdit) {
    return {
      ...commonFields,
      description: "",
      estimatedHours: "",
      name: "",
      additionalUnitGoals: [{ isPrimary: false, deliverableUnitId: undefined, targetQuantity: undefined }],
      primaryUnitGoals: [{ isPrimary: true, deliverableUnitId: undefined, targetQuantity: undefined }],
      billingClassificationId: "",
      workersCompCodeId: "",
      ...(taskGroupId && { groupId: taskGroupId }),
    }
  }

  if (groupTask) {
    return {
      ...commonFields,
      description: groupTask.description || "",
      name: groupTask.name || "",
      taskType: "summary-task",
    }
  }

  return {
    ...taskOrSubtask,
    ...commonFields,
    name: taskOrSubtask?.name || "",
    additionalUnitGoals: unitGoalInputs.filter(({ isPrimary }) => !isPrimary),
    primaryUnitGoals: unitGoalInputs.filter(({ isPrimary }) => isPrimary),
  }
}

const getInitialTaskType = (
  addTaskType: CreateOrEditTaskFormValues["taskType"] | undefined,
  taskOrSubtask?: EditTaskOrSubtaskQuery["task"]
): CreateOrEditTaskFormValues["taskType"] => {
  if (addTaskType) return addTaskType
  if (!taskOrSubtask) return "summary-task"
  return taskOrSubtask.groupId ? "sub-task" : "task"
}
