import { Alert, TextField } from "@mui/material"
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers"
import { isBefore, isEqual } from "date-fns"
import { useFormikContext } from "formik"
import { ChangeEvent, FC, useEffect } from "react"
import { useQuery } from "urql"
import { ProjectScheduleLabels } from "../../../components/Partials/Organizations/TabPanels/ScheduleSubPanels/ScheduleDetails"
import { CreateOrEditTaskFormValues } from "../../../components/Partials/Tasks/helpers/types"
import { GetOrgScheduleForProjectQuery } from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { Values } from "../../../pages/projects/create/_index"
import useScheduleCalculations from "../hooks/useScheduleCalculations"
import {
  calculateEarliestAndLatestDates,
  calculateEndDate,
  defaultWorkDays,
  getExpandedNonWorkDays,
} from "../utils/helpers"
import { CalculateManHoursInputField } from "./ManHoursInput"

const GetOrgScheduleDocument = graphql(`
  query GetOrgScheduleForProject {
    schedules(isDefault: true) {
      id
      isDefault
      workDays {
        label
        active
      }
      workHours {
        hours
        startTime
        endTime
      }
      nonWorkDays {
        id
        name
        dateRange
        active
      }
    }
  }
`)

type Schedule = GetOrgScheduleForProjectQuery["schedules"][0]

const needsUpdate = (date1?: Date | number | null, date2?: Date | number | null) => {
  if (!date1 || !date2) {
    if (date1 === date2) {
      return false
    }
    
    return true
  }

  return !isEqual(date1, date2)
}

export const WorkDayAndDateFields: FC<{
  schedule?: Schedule
  disabled?: boolean
  showManHours?: boolean
  initialValues?: CreateOrEditTaskFormValues
  projectDates?: [Date | null | undefined, Date | null | undefined]
}> = ({ schedule, initialValues, disabled = false, projectDates, showManHours = false }) => {
  const [{ data, error }] = useQuery({
    query: GetOrgScheduleDocument,
    pause: Boolean(schedule),
  })

  useHandleError(error, "There was a problem retrieving schedule templates. Please try again.")

  const schedules: Schedule[] = (data?.schedules as Schedule[]) || []
  const projectOrOrgSchedule: Schedule = schedule || schedules?.[0]

  const { values, setFieldValue, setValues } = useFormikContext<Values>()
  const { startDate, endDate, tasks, workDays, estimatedHours, duration } = values
  const [projectStartDate, projectEndDate] = projectDates || []

  const nonWorkingDays = getExpandedNonWorkDays(projectOrOrgSchedule?.nonWorkDays)
  const taskBuilderOverridingProjectDates = tasks?.some((task) => {
    return !!task.startDate || !!task.workDays
  })
  const thisTaskOverridesProjectDates =
    !tasks?.length &&
    ((values?.startDate && projectStartDate && values?.startDate < projectStartDate) ||
      (values?.endDate && projectEndDate && values?.endDate > projectEndDate))
  const { duration: newDuration, workDaysCount: newWorkDays } = useScheduleCalculations(
    startDate,
    endDate,
    schedule?.workDays || defaultWorkDays,
    projectOrOrgSchedule?.nonWorkDays,
    taskBuilderOverridingProjectDates ? tasks : undefined
  )

  // calculates the end date or work days/duration depending on which inputs have values
  useEffect(() => {
    if (workDays && startDate && !endDate) {
      const newEndDate = calculateEndDate(
        workDays,
        startDate,
        projectOrOrgSchedule?.workDays || defaultWorkDays,
        nonWorkingDays
      )
      if (needsUpdate(newEndDate, endDate)) {
        setFieldValue("endDate", newEndDate)
      }
    } else if (startDate && endDate) {
      if (needsUpdate(workDays, newWorkDays)) {
        setFieldValue("workDays", newWorkDays)
      }

      if (needsUpdate(duration, newDuration)) {
        setFieldValue("duration", newDuration)
      }
    }
  }, [
    duration,
    endDate,
    newDuration,
    newWorkDays,
    nonWorkingDays,
    projectOrOrgSchedule,
    setFieldValue,
    startDate,
    workDays,
  ])

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      startDate: null,
      endDate: null,
      workDays: null,
      duration: null,
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskBuilderOverridingProjectDates])

  useEffect(() => {
    if (!tasks) return
    const expandedNonWorkingDays = getExpandedNonWorkDays(schedule?.nonWorkDays)
    const { earliestStartDate, latestEndDate } = calculateEarliestAndLatestDates(
      tasks,
      schedule?.workDays || defaultWorkDays,
      expandedNonWorkingDays
    )

    // Only update the project startDate if it's different from the current value
    if (earliestStartDate && (!startDate || !isEqual(earliestStartDate, startDate))) {
      setFieldValue("startDate", earliestStartDate)
    }

    // Only update the project endDate if it's different from the current value
    if (latestEndDate && (!endDate || !isEqual(latestEndDate, endDate))) {
      setFieldValue("endDate", latestEndDate)
    }
  }, [schedule, setFieldValue, startDate, endDate, tasks])

  // EDIT TASK WORKFLOW: initializes start and end date
  useEffect(() => {
    if (!initialValues?.startDate || !initialValues?.endDate) return
    setValues((prev) => ({
      ...prev,
      startDate: initialValues.startDate,
      endDate: initialValues.endDate,
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleWorkDaysChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = e.target.value
    const number = Number(value)

    if (number < 0) {
      return
    }

    setValues((prev) => ({
      ...prev,
      endDate: null,
      workDays: value === "" ? null : number,
    }))
  }

  const handleStartDateChange = (
    value: Date | null,
    { validationError }: PickerChangeHandlerContext<DateValidationError>
  ) => {
    if (validationError || !value) {
      return
    }

    if (endDate && isBefore(endDate, value)) {
      setFieldValue("endDate", null)
    }

    setFieldValue("startDate", value)
  }

  const handleEndDateChange = (
    value: Date | null,
    { validationError }: PickerChangeHandlerContext<DateValidationError>
  ) => {
    if (validationError || !value) {
      return
    }

    if (startDate && isBefore(value, startDate)) {
      setFieldValue("startDate", null)
    }

    setFieldValue("endDate", value)
  }

  const isDisabledDate = (day: Date) => nonWorkingDays.some((d) => isEqual(day, d))

  return (
    <>
      {thisTaskOverridesProjectDates && (
        <Alert severity="info" color="warning" sx={{ marginY: "16px", padding: "4px 16px", borderRadius: "6px" }}>
          The project schedule will be updated to reflect the chosen dates and durations from tasks.
        </Alert>
      )}
      {taskBuilderOverridingProjectDates ? (
        <div className="flex flex-col">
          <Alert severity="info" color="warning" sx={{ marginY: "16px", padding: "4px 16px", borderRadius: "6px" }}>
            The project schedule has been updated to reflect the chosen dates and durations from the task builder.
          </Alert>
        </div>
      ) : (
        <>
          <div className="flex gap-x-2 mt-2 py-4">
            <TextField
              className="flex-auto"
              name="workDays"
              type="number"
              label="Work days"
              value={workDays || ""}
              onChange={handleWorkDaysChange}
              InputLabelProps={{ shrink: workDays !== null }}
              disabled={disabled}
            />
            <DatePicker
              className="flex-auto"
              label="Start Date"
              value={startDate ?? null}
              onChange={handleStartDateChange}
              shouldDisableDate={isDisabledDate}
              slotProps={{
                textField: {
                  id: "start-date",
                },
              }}
              disabled={disabled}
            />
            <DatePicker
              className="flex-auto"
              label="End Date"
              value={endDate ?? null}
              minDate={startDate ?? new Date()}
              onChange={handleEndDateChange}
              shouldDisableDate={isDisabledDate}
              slotProps={{
                textField: {
                  id: "end-date",
                },
              }}
              disablePast
              disabled={disabled}
            />
          </div>
          {showManHours && <CalculateManHoursInputField schedule={schedule} />}
        </>
      )}
      <ProjectScheduleLabels {...{ startDate, endDate, estimatedHours, workDays, duration }} />
    </>
  )
}
