import { Masonry } from "@mui/lab"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { FC, ReactNode, useContext, useState } from "react"
import { BiCalendar, BiMinusCircle, BiNote, BiPlusCircle, BiRuler } from "react-icons/bi"
import { CombinedError, useQuery } from "urql"
import * as Yup from "yup"
import { ResetScheduleModal } from "../../../features/Scheduling/components/ResetScheduleModal"
import { TaskScheduleForm } from "../../../features/Scheduling/components/TaskScheduleForm"
import useInheritedSchedule from "../../../features/Scheduling/hooks/useInheritedSchedule"
import {
  useCreateTaskGroupMutation,
  useGetTaskScheduleQuery,
  useTaskCreateMutation,
  useTaskEditMutation,
  useUpdateTaskGroupMutation,
} from "../../../graphql/generated/client-types-and-hooks"
import { graphql } from "../../../graphql/generated/gql"
import { useHandleError } from "../../../hooks/useHandleError"
import { warnOnExitStoreActions } from "../../../stores/warnOnExit"
import { AddButton } from "../../AddButton"
import { DevelopmentFeatureFlag } from "../../DevelopmentFeatureFlag"
import { DropDownMUI, DropDownMUIItem } from "../../DropDownMUI"
import { DatePicker } from "../../Formik/DatePicker"
import { RichContent } from "../../Formik/RichContent"
import { DeprecatedInput } from "../../Formik/StandardInput"
import { WorkersCompCodeSelect } from "../../Formik/WorkersCompCodeSelect"
import { AddNoteModal } from "../../Modals/components/AddNoteModal"
import { useModalProps } from "../../Modals/hooks/useModalProps"
import { MultiUnitInput } from "../../MultiUnitInput"
import { NoteCard } from "../../NoteCard"
import { errorSnack, successSnack } from "../../Notistack/ThemedSnackbars"
import { SectionHeader } from "../../PageSectionHeader"
import { RenderIf } from "../../RenderIf"
import { SingleDrawerContext } from "../Drawer/components/Elements/Drawer"
import { DrawerFooter } from "../Drawer/components/Elements/DrawerFooter"
import { TaskTypeSelector } from "./TaskTypeSelector"
import { getInitialValues } from "./helpers/getInitialValues"
import { CreateOrEditTaskFormValues, Props, TaskAccordionState } from "./helpers/types"

const EditTaskOrSubtaskDocument = graphql(`
  query EditTaskOrSubtask($taskId: String!) {
    task(id: $taskId) {
      id
      name
      groupId
      metadata {
        label
        content
      }
      projectId
      archived
      createdAt
      startDate
      endDate
      estimatedHours
      isDefault
      isComplete
      description
      hasReportableUnit
      workersCompCodeId
      unitGoals {
        id
        deliverableUnitId
        targetQuantity
        isPrimary
        deliverableUnit {
          id
          unitOfMeasure
        }
      }
    }
  }
`)

const ProjectTaskGroupsDocument = graphql(`
  query ProjectTaskGroups($projectId: String!) {
    taskGroups(projectId: $projectId) {
      id
      name
      completedTaskCount
      taskCount
    }
  }
`)

const ProjectDetailsDocument = graphql(`
  query ProjectContractId($projectId: String!) {
    project(id: $projectId) {
      id
      contractId
      endDate
      orgScheduleId
      schedule {
        id
        workDays {
          label
          active
        }
        workHours {
          hours
          startTime
          endTime
        }
        nonWorkDays {
          id
          name
          dateRange
          active
        }
      }
      scheduledBreaks {
        id
        breakTask {
          id
          name
          isUnpaid
          projectId
        }
        breakTaskId
        durationInMinutes
        isActive
        localizedStartTime
        name
      }
      startDate
    }
  }
`)

const EditGroupTaskDocument = graphql(`
  query EditGroupTask($id: String!) {
    taskGroup(id: $id) {
      id
      completedTaskCount
      description
      name
      notes {
        label
        content
      }
      projectId
      taskCount
    }
  }
`)

const validationSchema = Yup.object().shape({
  taskType: Yup.string(),
  name: Yup.string().trim().required("Required").label("Task name"),
  groupId: Yup.string()
    .nullable()
    .when("taskType", {
      is: "sub-task",
      then: (schema) => schema.required("Task is required"),
    }),
  primaryUnitGoals: Yup.array(
    Yup.object()
      .nullable()
      .shape(
        {
          deliverableUnitId: Yup.string().when("targetQuantity", {
            is: (exist: number) => !!exist,
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema.nullable(),
          }),
          targetQuantity: Yup.number().when("deliverableUnitId", {
            is: (exist: string) => !!exist,
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema,
          }),
        },
        [["deliverableUnitId", "targetQuantity"]]
      )
  ),
  additionalUnitGoals: Yup.array(
    Yup.object().shape({
      deliverableUnitId: Yup.string().when("targetQuantity", {
        is: (exist: number) => !!exist,
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema,
      }),
      targetQuantity: Yup.number(),
    })
  ),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().nullable().min(Yup.ref("startDate"), "End Date must be after Start date"),
  description: Yup.string().nullable(),
  estimatedHours: Yup.number().nullable(),
  workersCompCodeId: Yup.string().nullable(),
  metadata: Yup.array(
    Yup.object().shape({
      content: Yup.string().trim(),
      label: Yup.string().trim(),
    })
  ),
})

export const CreateOrEditTaskForm: FC<Props> = ({ task, onError, taskType = "task", taskGroupId }) => {
  const addNoteModalProps = useModalProps("Add Note")

  const { handleClose } = useContext(SingleDrawerContext)
  const { setHasUnsavedChanges } = warnOnExitStoreActions
  const [_isDirty, setIsDirty] = useState(false)

  const projectId = task?.projectId ?? ""
  const taskId = task?.id ?? ""

  const [{ data: projectData, error: projectDetailsError }] = useQuery({
    query: ProjectDetailsDocument,
    variables: { projectId },
  })

  const [{ data: taskScheduleData, error: taskScheduleError }] = useGetTaskScheduleQuery({
    variables: { taskId },
    pause: !taskId || taskType === "summary-task",
  })

  const parentSchedule = projectData?.project?.schedule
  const parentBreaks = projectData?.project?.scheduledBreaks
  const projectStartDate = projectData?.project?.startDate
  const projectEndDate = projectData?.project?.endDate

  const taskSchedule = taskScheduleData?.task?.schedule
  const taskScheduledBreaks = taskScheduleData?.task?.scheduledBreaks

  const resetScheduleModalProps = useModalProps("Reset Task Schedule?")
  const openResetScheduleModal = () => resetScheduleModalProps.handleOpenModal()

  const [expanded, setExpanded] = useState<TaskAccordionState>({
    taskSchedule: false,
    taskNotes: false,
    projectNotes: false,
    reportingUnits: false,
  })

  const scheduleHooks = useInheritedSchedule(
    parentSchedule,
    parentBreaks,
    projectId,
    taskSchedule,
    taskScheduledBreaks,
    "task"
  )

  const [{ error: updateTaskError }, updateTask] = useTaskEditMutation()
  const [{ error: createTaskError }, createTask] = useTaskCreateMutation()
  const [{ error: createTaskGroupError }, createTaskGroup] = useCreateTaskGroupMutation()
  const [{ error: updateTaskGroupError }, updateTaskGroup] = useUpdateTaskGroupMutation()

  // Query to populate summary task drop down for subtasks
  const [{ data: taskGroupsData, fetching: fetchingTaskGroups, error: taskGroupQueryError }] = useQuery({
    query: ProjectTaskGroupsDocument,
    variables: { projectId },
    pause: !projectId,
  })

  // Query to populate edit task/subtask form
  const [{ data: taskData, fetching: fetchingTask, error: taskQueryError }] = useQuery({
    query: EditTaskOrSubtaskDocument,
    variables: { taskId, projectId },
    pause: !taskId || taskType === "summary-task",
  })

  // Query to populate edit group task form
  const [{ data: taskGroupQuery }] = useQuery({
    query: EditGroupTaskDocument,
    variables: { id: taskGroupId ?? "" },
    pause: !taskGroupId || taskType !== "summary-task",
  })

  const selectedTaskGroup = taskGroupQuery?.taskGroup
  const selectedTaskOrSubtask = taskData?.task
  const isCreationNotEdit = !taskId

  useHandleError(projectDetailsError, "There was an error retrieving project details.")
  useHandleError(taskScheduleError, "There was an error retrieving schedule details.")
  useHandleError(updateTaskError, "There was an error saving the task details.")
  useHandleError(createTaskError, "There was an error saving the task details.")
  useHandleError(createTaskGroupError, "There was an error saving the task group details.")
  useHandleError(updateTaskGroupError, "There was an error editing the task group details.")
  useHandleError(taskQueryError, "There was an error retrieving task details.")
  useHandleError(taskGroupQueryError, "There was an error loading the project tasks")

  const handleError = (err: CombinedError | undefined, message: string) => {
    errorSnack(message)
    console.error(err)
    onError?.()
  }

  const handleSuccess = (message: string) => {
    if (message) {
      successSnack(message)
    }
  }

  const possibleTaskGroups: DropDownMUIItem[] = (taskGroupsData?.taskGroups || [])
    .filter((tg) => tg.completedTaskCount === 0 || tg.completedTaskCount !== tg.taskCount)
    .map((tg) => ({
      value: tg.id,
      label: tg.name,
    }))

  const onSubmit = (values: CreateOrEditTaskFormValues) => {
    if (isCreationNotEdit) {
      onSubmitCreate(values)
    } else {
      onSubmitEdit(values)
    }
  }

  const onSubmitCreate = async (values: CreateOrEditTaskFormValues) => {
    if (values.taskType === "summary-task") {
      createTaskGroup({
        name: values.name,
        projectId,
        description: values.description,
        notes: values.metadata,
      }).then((result) => {
        if (result.error) {
          return handleError(result.error, "There was an error saving the summary task.")
        }

        setHasUnsavedChanges(false)
        handleClose()
        return handleSuccess("Successfully created the summary task.")
      })
    } else {
      const { schedule, scheduledBreaks } = await scheduleHooks.getScheduleAndBreaksData()

      // Formik form validation for arrays of unknown size requires the array item keys to at least exist.
      // Therefore, conditional array items may be expressed with undefined values.
      // Here we will remove those values.
      const unitGoals = [...(values.primaryUnitGoals || []), ...(values.additionalUnitGoals || [])].filter(
        ({ deliverableUnitId }) => deliverableUnitId
      )

      const metadata = values.metadata.filter(({ label, content }) => !!label || !!content)

      createTask({
        name: values.name,
        description: values.description ?? undefined,
        groupId: values.groupId ?? undefined,
        projectId,
        workersCompCodeId: values.workersCompCodeId ?? undefined,
        unitGoals: unitGoals.map((unitGoal) => ({
          ...unitGoal,
          deliverableUnitId: unitGoal.deliverableUnitId!,
          targetQuantity: unitGoal.targetQuantity ?? 0,
        })),
        endDate: values?.endDate || undefined,
        startDate: values?.startDate || undefined,
        estimatedHours: values?.estimatedHours ? +values?.estimatedHours : undefined,
        metadata,
        scheduledBreaks,
        // remove inactive nonWorkDays here (the user has edited an org or project schedule and doesn't want these included)
        schedule: { ...schedule, nonWorkDays: schedule?.nonWorkDays?.filter((day) => day.active) },
      }).then((result) => {
        if (result.error) {
          return handleError(result.error, "There was an error saving the task details.")
        }

        setHasUnsavedChanges(false)
        handleClose()
        handleSuccess("Successfully created the task.")
      })
    }
  }

  const handleEditTaskGroup = async (values: CreateOrEditTaskFormValues) => {
    updateTaskGroup({
      id: taskGroupId ?? "",
      ...values,
    }).then((result) => {
      if (result.error) {
        return handleError(result.error, "There was an error saving the summary task details.")
      }
      handleSuccess("Updated summary task details.")
    })
  }

  const onSubmitEdit = async (values: CreateOrEditTaskFormValues) => {
    if (values.taskType !== "sub-task") {
      values.groupId = null
    }

    const { schedule, scheduledBreaks } = await scheduleHooks.getScheduleAndBreaksData()

    const unitGoals = [...(values.primaryUnitGoals || []), ...(values.additionalUnitGoals || [])].filter(
      ({ deliverableUnitId }) => deliverableUnitId
    )

    if (values.taskType !== "summary-task") {
      updateTask({
        ...values,
        id: task?.id ?? "",
        projectId,
        unitGoals: unitGoals.map((unitGoal) => ({
          ...unitGoal,
          deliverableUnitId: unitGoal.deliverableUnitId!,
          targetQuantity: unitGoal.targetQuantity ?? 0,
        })),
        schedule,
        scheduledBreaks,
        estimatedHours: values?.estimatedHours ? +values?.estimatedHours : undefined,
      }).then((result) => {
        if (result.error) {
          return handleError(result.error, "There was an error saving the task details.")
        }
        setHasUnsavedChanges(false)
        handleClose()
        return handleSuccess("Updated the task details.")
      })
    } else {
      handleEditTaskGroup(values)
    }
  }

  const toggleAccordion = (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded((prev) => ({ ...prev, [panel]: isExpanded }))
  }

  const isLoadingTaskData = fetchingTask && !!taskId && !taskData
  const isLoadingProjectTaskGroupData = fetchingTaskGroups && !taskGroupsData

  if (isLoadingProjectTaskGroupData || isLoadingTaskData) {
    return <Skeleton />
  }

  const initialValues: CreateOrEditTaskFormValues = getInitialValues(
    taskType,
    isCreationNotEdit,
    selectedTaskOrSubtask,
    selectedTaskGroup,
    taskGroupId
  )

  const action = isCreationNotEdit ? "New" : "Edit"
  const typeOfTask = taskType?.replace("-", " ") ?? "Task"
  const header = `${action} ${typeOfTask}`

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, values, setFieldValue, handleChange, dirty }) => {
        setTimeout(
          () =>
            setIsDirty((previous) => {
              if (previous !== dirty) {
                setHasUnsavedChanges(dirty)
                return dirty
              }
              return previous
            }),
          0
        )

        const showProjectDateAlert =
          (values?.startDate && projectStartDate && values.startDate < projectStartDate) ||
          (values?.endDate && projectEndDate && values.endDate > projectEndDate)
        return (
          <Form className="flex flex-col gap-4">
            <Typography fontSize="36px" lineHeight="40px" fontWeight={700} marginBottom={1} textTransform="capitalize">
              {header}
            </Typography>
            <div className="flex flex-col gap-4">
              <section>
                <div className="flex flex-col w-full border rounded-lg px-8 pb-8">
                  <div className="flex gap-x-1 pt-8">
                    <Typography variant="h5">Basic Info</Typography>
                    <Typography variant="h5" className="text-gray-500">
                      Required *
                    </Typography>
                  </div>

                  {(isCreationNotEdit || values.taskType !== "summary-task") && (
                    <div className="pb-4">
                      <TaskTypeSelector {...{ isCreationNotEdit }} />
                    </div>
                  )}

                  <div className="w-2/3 grid gap-y-4">
                    <div className="grid gap-y-4">
                      {values.taskType === "sub-task" && (
                        <DropDownMUI items={possibleTaskGroups} fieldName="groupId" label="Summary task" />
                      )}
                      <TextField
                        id="task-name"
                        name="name"
                        label="Task name"
                        value={values.name}
                        onChange={handleChange}
                        required
                        fullWidth
                      />
                    </div>

                    {values.taskType !== "summary-task" && (
                      <>
                        {showProjectDateAlert && (
                          <Alert
                            severity="info"
                            color="warning"
                            sx={{ marginY: "16px", padding: "4px 16px", borderRadius: "6px" }}
                          >
                            The project schedule will be updated to reflect the chosen dates and durations from tasks.
                          </Alert>
                        )}
                        <div className="flex flex-col md:flex-row grow gap-x-1 gap-y-2 md:gap-y-0">
                          <DatePicker id="start-date" name="startDate" label="Start Date" />
                          <DatePicker id="end-date" name="endDate" label="End Date" />
                        </div>
                        <DeprecatedInput
                          id="man-hours"
                          label=""
                          name="estimatedHours"
                          placeholder="Enter man-hours"
                          type="number"
                          containerClassName="grow"
                          labelClassName="hidden"
                        />

                        <DevelopmentFeatureFlag name="Workers Comp Code">
                          <RenderIf permissionsInclude="timeEntry:export">
                            <WorkersCompCodeSelect name="workersCompCodeId" />
                          </RenderIf>
                        </DevelopmentFeatureFlag>
                      </>
                    )}

                    <RichContent name="description" label="Task description" initialValue={values.description ?? ""} />
                  </div>
                </div>
              </section>

              <Accordion
                hidden={values.taskType === "summary-task"}
                className="border px-4"
                expanded={expanded.taskSchedule}
                onChange={toggleAccordion("taskSchedule")}
              >
                <AccordionTitle isExpanded={expanded.taskSchedule}>
                  <SectionHeader
                    title="Task Schedule"
                    icon={<BiCalendar className="size-6" />}
                    size="small"
                    hideBorder
                  />
                </AccordionTitle>
                <AccordionDetails>
                  <TaskScheduleForm
                    schedule={scheduleHooks.currentSchedule}
                    scheduledBreaks={scheduleHooks.currentScheduledBreaks}
                    onEdit={scheduleHooks.onEditSchedule}
                    initialValues={initialValues}
                    isInheritedSchedule={scheduleHooks.isInheritedSchedule}
                    onResetClicked={openResetScheduleModal}
                    projectDates={[projectStartDate, projectEndDate]}
                  />
                </AccordionDetails>
              </Accordion>

              <Accordion className="border px-4" expanded={expanded.taskNotes} onChange={toggleAccordion("taskNotes")}>
                <AccordionTitle isExpanded={expanded.taskNotes}>
                  <SectionHeader title="Task Notes" icon={<BiNote className="size-6" />} size="small" hideBorder />
                </AccordionTitle>
                <AccordionDetails>
                  {values?.metadata?.length > 0 && (
                    <Masonry
                      columns={{ xs: 2, sm: 3, md: 3, lg: 4 }}
                      spacing={2}
                      defaultHeight={450}
                      defaultColumns={4}
                    >
                      {values?.metadata.map((note, i) => (
                        <NoteCard
                          key={`${note.label}-${i}`}
                          note={note}
                          onDelete={(noteToDelete) => {
                            setFieldValue(
                              "metadata",
                              values.metadata.filter(
                                (metadata) =>
                                  metadata.label !== noteToDelete.label && metadata.content !== noteToDelete.content
                              )
                            )
                            values.metadataToRemove.push(noteToDelete)
                          }}
                        />
                      ))}
                    </Masonry>
                  )}
                  <AddButton label="Add note" onClick={() => addNoteModalProps.handleOpenModal()} />
                </AccordionDetails>
              </Accordion>

              <Accordion
                hidden={values.taskType === "summary-task"}
                className="border px-4"
                expanded={expanded.reportingUnits}
                onChange={toggleAccordion("reportingUnits")}
              >
                <AccordionTitle isExpanded={expanded.reportingUnits}>
                  <SectionHeader
                    title="Reporting Units"
                    icon={<BiRuler className="size-6" />}
                    size="small"
                    hideBorder
                  />
                </AccordionTitle>
                <AccordionDetails>
                  <>
                    <MultiUnitInput
                      taskId={taskId}
                      contractId={projectData?.project?.contractId ?? ""}
                      estimatedHours={values.estimatedHours}
                      isPrimary={true}
                      name="primaryUnitGoals"
                    />
                    <MultiUnitInput
                      taskId={taskId}
                      contractId={projectData?.project?.contractId ?? ""}
                      isPrimary={false}
                      name="additionalUnitGoals"
                    />
                  </>
                </AccordionDetails>
              </Accordion>

              <DrawerFooter>
                <div className="col-span-12 flex justify-start flex-col md:flex-row gap-4 md:gap-6 md:max-w-md ">
                  <Button color="primary" variant="contained" type="submit" disabled={isSubmitting} size="large">
                    Save task
                  </Button>
                  <Button variant="text" type="button" onClick={handleClose} size="large">
                    Cancel
                  </Button>
                  {dirty && (
                    <Typography className="text-red-500" fontSize={12} fontWeight={400} marginTop={1.5}>
                      You have unsaved changes
                    </Typography>
                  )}
                </div>
              </DrawerFooter>
            </div>
            {addNoteModalProps.isOpen && (
              <AddNoteModal
                modalProps={addNoteModalProps}
                parentName={task?.name ?? "Task"}
                onSave={(newNote: FormikValues) => {
                  setFieldValue("metadata", [...values.metadata, newNote])
                }}
              />
            )}

            {resetScheduleModalProps?.isOpen && (
              <ResetScheduleModal
                modalProps={resetScheduleModalProps}
                onResetSchedule={scheduleHooks.resetSchedule}
                isTaskReset
              />
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

const AccordionTitle: FC<{ children: ReactNode; isExpanded: boolean }> = ({ children, isExpanded }) => (
  <AccordionSummary
    expandIcon={isExpanded ? <BiMinusCircle className="size-6" /> : <BiPlusCircle className="size-6" />}
  >
    {children}
  </AccordionSummary>
)
